import React from 'react';
import { useStyletron } from 'styletron-react';
import { FormScreenWrapper, GridScreenWrapper, ServiceDrivenViewSet } from '@samc/screen-config-core';
import {
  toastError,
  ToastMessage,
  Toggle,
  ToggleOption,
  Text,
  Loader,
  toastSuccess,
  useDirtinessSync,
  useDirtinessPrompt,
} from '@samc/react-ui-core';
import { CustomScreenParams } from '@samc/screen-config-core/lib/contexts/TabOverrideContext/TabOverrideContext';
import { DomainViewMember, TaskRequest, TaskResponse, TaskStatus } from '@samc/screen-config-api';
import TaskResponsePopup from '@samc/screen-config-core/lib/molecules/TaskResponsePopup/TaskResponsePopup';
import { GridFieldConfiguration } from '@samc/react-ui-grid';
import { useQueryClient } from 'react-query';
import { useApiContext } from '../../../hooks/useApiContext';
import './AddAssetsOrValuationGroups.scss';
import { useLiftAndSiftQuery } from '../../../hooks/useLiftAndSiftQuery/useLiftAndSiftQuery';
import { apiProvider } from '../../../BidEngageApi/api/Requests';
import { TwoGrids } from '../../molecules/TwoGrids/TwoGrids';

interface TabData {
  domainId: string;
  searchFields: string[];
  searchGridDescription: string;
  label: string;
  columns: GridFieldConfiguration[];
  queryMembers: DomainViewMember[];
  primaryKeyName: string;
}

const toggleOptions: ToggleOption<string, TabData>[] = [
  {
    displayText: 'ASSETS',
    hoverText: 'Add Assets to Bid Group',
    id: 'assets',
    data: {
      domainId: 'BID_AssetReplica',
      label: 'Find Assets to add to the Bid Group',
      searchFields: ['ReferenceId', 'Name', 'InvestmentName'],
      searchGridDescription:
        '*Search by Id, Name, or Investment Name. Assets already added to any Bid Group will not be enabled for selection.',
      primaryKeyName: 'Id',
      queryMembers: [
        {
          scalarExpression: `[ReferenceId]`,
          scalarDisplayName: 'ReferenceId',
        },
        {
          scalarExpression: '[Name]',
          scalarDisplayName: 'Name',
        },
        {
          scalarExpression: '[InvestmentId]',
          scalarDisplayName: 'InvestmentId',
        },
        { scalarExpression: '[InvestmentName]', scalarDisplayName: 'InvestmentName' },
        {
          scalarExpression: `[FundId]`,
          scalarDisplayName: 'FundId',
        },
        {
          scalarExpression: `[FundName]`,
          scalarDisplayName: 'FundName',
        },
        {
          scalarExpression: `[Portfolio_Id]`,
          scalarDisplayName: 'Portfolio_Id',
        },
        {
          scalarExpression: `[Id]`,
          scalarDisplayName: 'Id',
        },
        {
          scalarExpression: '[HasBidGroupItem]',
          scalarDisplayName: 'HasBidGroupItem',
        },
      ],
      columns: [
        { field: 'ReferenceId', headerName: 'Asset Id' },
        { field: 'Name', displayNameRule: 'Asset Name' },
        { field: 'Id', displayNameRule: 'Id', visibleRule: 'false' },
        {
          field: 'InvestmentId',
          displayNameRule: 'InvestmentId',
          visibleRule: 'false',
        },
        { field: 'InvestmentName', displayNameRule: 'Investment Name' },
        {
          field: 'FundId',
          displayNameRule: 'FundId',
          visibleRule: 'false',
        },
        { field: 'FundName', displayNameRule: 'Fund Name' },
        {
          field: 'Portfolio_Id',
          displayNameRule: 'Portfolio_Id',
          visibleRule: 'false',
        },
      ],
    },
  },
  {
    displayText: 'VALUATION GROUPS',
    hoverText: 'Add Valuation Groups to Bid Group',
    id: 'valuationGroups',
    data: {
      domainId: 'BID_ValuationGroupReplica',
      label: 'Find Valuation Groups to add to the BidGroup',
      searchFields: ['ValuationGroupName'],
      searchGridDescription:
        '*Search by Group Name. Valuation Groups already added to any Bid Group will not be enabled for selection.',
      primaryKeyName: 'ValuationGroupId',
      queryMembers: [
        {
          scalarExpression: `[ReferenceId]`,
          scalarDisplayName: 'ReferenceId',
        },
        {
          scalarExpression: '[ValuationGroupName]',
          scalarDisplayName: 'ValuationGroupName',
        },
        {
          scalarExpression: `[PortfolioId]`,
          scalarDisplayName: 'PortfolioId',
        },
        {
          scalarExpression: `[ValuationGroupId]`,
          scalarDisplayName: 'ValuationGroupId',
        },
        {
          scalarExpression: '[HasBidGroupItem]',
          scalarDisplayName: 'HasBidGroupItem',
        },
      ],
      columns: [
        { field: 'ReferenceId', headerName: 'Asset Id' },
        { field: 'ValuationGroupName', displayNameRule: 'Valuation Group Name' },
        { field: 'ValuationGroupId', displayNameRule: 'ValuationGroupId', visibleRule: 'false' },
        {
          field: 'PortfolioId',
          displayNameRule: 'PortfolioId',
          visibleRule: 'false',
        },
      ],
    },
  },
];

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noOp = (): void => {};

const emptyResponsesArray: TaskResponse[] = [];
const defaultRequestsArray: TaskRequest[] = [
  {
    domainId: '',
    requestIdentifier: '',
    payload: {},
  },
];

interface InnerAddAssetsOrValuationGroupsProps extends CustomScreenParams {
  primaryKeyValue: NonNullable<CustomScreenParams['primaryKeyValue']>;
  refreshViewSet: () => void;
}

const InnerAddAssetsOrValuationGroups = ({
  filters,
  primaryKeyValue,
  refreshViewSet,
}: InnerAddAssetsOrValuationGroupsProps): React.ReactElement => {
  const [selectedRows, setSelectedRows] = React.useState<Record<string, unknown>[]>([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [css] = useStyletron();
  const api = useApiContext();
  const queryClient = useQueryClient();
  const existingItemsKey = 'existingBidGroupItems';

  const dirtinessScope = React.useRef<HTMLDivElement>(null);
  const { promptAndContinue } = useDirtinessPrompt({ scope: dirtinessScope });

  const [requestResponse, setRequestResponse] = React.useState<{
    request: TaskRequest[];
    response: TaskResponse[];
  }>();

  const setVisible = React.useCallback((): void => setRequestResponse(undefined), []);

  const [selectedTab, setSelectedTab] = React.useState(toggleOptions[0]);

  const bidGroupItemPayload = React.useMemo(
    () =>
      selectedRows
        .filter((u): boolean => !u.__DELETED__)
        .reduce(
          (acc, row, index) => ({
            ...acc,
            [`${-index - 1}`]: {
              BidGroupId: String(primaryKeyValue),
              Portfolio_Id: row.Portfolio_Id ? String(row.Portfolio_Id) : String(row.PortfolioId),
              AssetId: row.Id ? String(row.Id) : undefined,
              ValuationGroupId: row.ValuationGroupId ? String(row.ValuationGroupId) : undefined,
              Name: row.Name ?? row.ValuationGroupName,
              __ADDED__: true,
            },
          }),
          {},
        ) as Record<string, unknown>,
    [selectedRows, primaryKeyValue],
  );

  const itemsInGroup = useLiftAndSiftQuery<{ AssetId: string; ValuationGroupId: string }>(existingItemsKey, {
    domainId: 'BID_BidGroupItem',
    primaryViewId: '-1',
    filterIds: [],
    paging: { start: 0, stop: 1000 },
    adhocListViewMembers: [
      { scalarExpression: '[AssetId]', scalarDisplayName: 'AssetId' },
      { scalarExpression: '[ValuationGroupId]', scalarDisplayName: 'ValuationGroupId' },
    ],
    sorting: {
      order: 'asc',
      orderBy: { scalarExpression: '[Name]' },
    },
    summaryMode: false,
    adhocFilter: {
      filterName: 'adhoc',
      advancedInd: true,
      expressionLang: 'Centric',
      advancedExpression: `[BidGroupId] = '${primaryKeyValue}'`,
    },
  });

  const submitWithOverrides = React.useCallback(
    async (overrideCodes?: string[]): Promise<void> => {
      setIsSubmitting(true);
      const payload = bidGroupItemPayload as TaskRequest['payload'];
      const showError = (): void => {
        toastError(<ToastMessage title="Error" message="Something went wrong" />);
      };
      await apiProvider
        .submitTask(
          'BID_BidGroupItem',
          'BID_BidGroupItem',
          `${selectedTab.data!.domainId} Grid`,
          payload,
          api.requestInit ?? {},
          false,
          undefined,
          overrideCodes,
        )
        .then(([response, request]) => {
          if (response.statusCode !== TaskStatus.Completed) {
            if (!overrideCodes) {
              setRequestResponse({ request: [request], response: [response] });
            }
          } else {
            toastSuccess(<ToastMessage title="Success" message="Bid Group saved successfully" />);
            queryClient.invalidateQueries([existingItemsKey, selectedTab.data?.domainId]);
            itemsInGroup.refetch();
            setSelectedRows([]); // Resets grid selection data to ensure form is not dirty anymore
            refreshViewSet();
          }
        })
        .catch(showError)
        .finally(() => setIsSubmitting(false));
    },
    [api.requestInit, bidGroupItemPayload],
  );

  const submit = React.useCallback(() => submitWithOverrides(), [submitWithOverrides]);
  const reset = React.useCallback(() => {
    setSelectedRows([]);
  }, []);

  const changeTab = (newTab: ToggleOption<string, TabData>): void => {
    setSelectedTab(newTab);
    setSelectedRows([]);
  };

  const checkTabChange = (newTab: ToggleOption<string, TabData>): void => {
    if (selectedRows.length > 0) {
      promptAndContinue((_) => changeTab(newTab)).catch(noOp);
      return;
    }
    changeTab(newTab);
  };

  const memoizedExistingRecordIds = React.useMemo(
    () =>
      itemsInGroup.data?.Data.map((x) => {
        if (selectedTab.id === 'assets') return x.AssetId ?? '';
        return x.ValuationGroupId ?? '';
      }).filter((x) => x !== '') ?? [],
    [itemsInGroup.data?.Data, selectedTab.id],
  );

  // will expose the dirtiness state to the global handler
  useDirtinessSync({
    isDirty: selectedRows.length > 0,
    data: selectedRows,
    scope: dirtinessScope,
    onSave: submit,
    onReset: reset,
    blockChildren: true, // note: we want to keep double submission from happening by blocking lower handlers
  });

  return (
    <div ref={dirtinessScope} className={css({ display: 'flex', flex: 1, overflow: 'hidden' })}>
      {selectedTab.data && (
        <>
          <FormScreenWrapper
            formViewId="BID_AssetsByBidGroup"
            className={css({ display: 'flex', flex: 1 })}
            childrenStyle={{ display: 'flex', flex: '1 0 auto', flexDirection: 'column', marginBottom: 0 }}
            showContent={false} // use form as placeholder, will never be dirty
            ServiceDrivenViewSet={ServiceDrivenViewSet}
            GridScreenWrapper={GridScreenWrapper}
            onSubmit={submit}
          >
            <div className="grid-toggle">
              <Toggle<string, TabData> options={toggleOptions} value={selectedTab.id} onSelect={checkTabChange} />
            </div>
            <Loader isLoading={itemsInGroup.isLoading || isSubmitting} isError={itemsInGroup.isError}>
              <TwoGrids
                selectedRows={selectedRows}
                setData={setSelectedRows}
                onSubmit={submit}
                portfolioFilter={
                  filters && filters.length > 0
                    ? filters[0]
                        .toString()
                        .replace(
                          'Portfolio_Id',
                          selectedTab.data.queryMembers.some((q) => q.scalarExpression.includes('PortfolioId'))
                            ? 'PortfolioId'
                            : 'Portfolio_Id',
                        )
                    : ''
                }
                label={selectedTab.data.label}
                columns={selectedTab.data.columns}
                queryMembers={selectedTab.data.queryMembers}
                domainId={selectedTab.data.domainId}
                searchFields={selectedTab.data.searchFields}
                searchGridDescription={selectedTab.data.searchGridDescription}
                primaryKeyName={selectedTab.data.primaryKeyName}
                existingRecordIds={memoizedExistingRecordIds}
                additionalIsRowSelectableCriteria={(row): boolean => !row.data?.HasBidGroupItem}
              />
            </Loader>
          </FormScreenWrapper>
          <TaskResponsePopup
            responses={requestResponse?.response ?? emptyResponsesArray}
            requests={requestResponse?.request ? requestResponse.request : defaultRequestsArray}
            visible={requestResponse?.response !== undefined}
            setVisible={setVisible}
            submitWithOverrides={submitWithOverrides}
          />
        </>
      )}
    </div>
  );
};

interface AddAssetsOrValuationGroupsProps extends CustomScreenParams {
  primaryKeyValue: string | undefined;
  refreshViewSet: () => void;
}
export const AddAssetsOrValuationGroups = (props: AddAssetsOrValuationGroupsProps): React.ReactElement => {
  const { primaryKeyValue, refreshViewSet } = props;

  if (!primaryKeyValue) {
    return (
      <div style={{ padding: '3rem' }}>
        <Text variant="large" weight="bold">
          Please fill out the required information on the Bid Group Info tab and save first.
        </Text>
      </div>
    );
  }

  return (
    <InnerAddAssetsOrValuationGroups {...props} primaryKeyValue={primaryKeyValue} refreshViewSet={refreshViewSet} />
  );
};

export default AddAssetsOrValuationGroups;
