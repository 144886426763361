/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { ChangeDataArgs, Grid, GridFieldConfiguration, GridProps } from '@samc/react-ui-grid';
import { DomainViewMember } from '@samc/screen-config-api';
import { IRowNode } from 'ag-grid-community';
import { GridSearch } from '../GridSearch/GridSearch';
import './TwoGrids.scss';

interface TwoGridProps extends Pick<GridProps, 'onSubmit'> {
  selectedRows: Record<string, unknown>[];
  setData: React.Dispatch<React.SetStateAction<Record<string, unknown>[]>>;
  portfolioFilter?: string;
  columns: GridFieldConfiguration[];
  queryMembers: DomainViewMember[];
  domainId: string;
  label: string;
  searchFields: string[];
  searchGridDescription: string;
  primaryKeyName: string;
  existingRecordIds: string[];
  containerRef?: React.Ref<HTMLDivElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalIsRowSelectableCriteria?: (node: IRowNode<any>) => boolean;
}

export const TwoGrids = (prop: TwoGridProps): React.ReactElement => {
  const {
    selectedRows,
    setData,
    onSubmit,
    portfolioFilter,
    columns,
    queryMembers,
    label,
    domainId,
    searchFields,
    primaryKeyName,
    searchGridDescription,
    existingRecordIds,
    containerRef,
    additionalIsRowSelectableCriteria,
  } = prop;

  const handleDataChanged = React.useCallback(
    (args: ChangeDataArgs<Record<string, unknown>>) => {
      const changedData = Object.values(args.changedData);
      const rows = selectedRows.filter((row): boolean => !changedData.some((r): boolean => r.Id === row.Id));
      setData([...changedData, ...rows]);
    },
    [setData, selectedRows],
  );

  return (
    <div className="twogrid" ref={containerRef}>
      <GridSearch
        rightGridRows={selectedRows}
        onGridRowsChanged={setData}
        portfolioFilter={portfolioFilter}
        columns={columns}
        queryMembers={queryMembers}
        searchFields={searchFields}
        searchGridDescription={searchGridDescription}
        label={label}
        domainId={domainId}
        existingRecordIds={existingRecordIds}
        primaryKeyName={primaryKeyName}
        additionalIsRowSelectableCriteria={additionalIsRowSelectableCriteria}
      >
        <Grid
          isEditingDefault={false}
          suppressEditToggle
          suppressAddRowButton
          suppressDeleteButton={false}
          suppressClearFilter={false}
          suppressExcelExport={false}
          suppressRowSpacing
          suppressDeleteConfirmationModal
          suppressFullScreen={false}
          suppressColumnFilters={false}
          data={selectedRows}
          fields={columns}
          onChangeData={handleDataChanged}
          onSubmit={onSubmit}
        />
      </GridSearch>
    </div>
  );
};

export default TwoGrids;
